import React from "react"
import Layout from "../components/global/layout"
import SEO from "../components/global/seo"
import SingleProduct from "../components/single-product-page/singleProduct"

import coldSoreProduct from "../images/product-and-package/cold-sore-cream-updated.jpg"
import coldSoreImage from "../images/products-without-packaging/cold-sore-cream.png"

import bootsLogo from "../images/stockists/boots.png"
import tescoLogo from "../images/stockists/tesco.png"
import superdrugLogo from "../images/stockists/superdrug.png"
// import morrisonsLogo from "../images/stockists/morrisons.png"
import amazonLogo from "../images/stockists/amazon.png"

const product = {
  image: coldSoreProduct,
  productImage: coldSoreImage,
  title: (
    <h1 className="product-overview__heading">
      Cold Sore <br />
      Cream <span className="small">docosanol.</span>
    </h1>
  ),
  alt: "Cold Sore Cream.",
  subTitle: (
    <h2 className="product-overview__sub-heading">
      <br />
      helps stop the cold sore virus at the very first sign.*
      <br />
    </h2>
  ),
  disclaimer: "",
  overviewCopy: (
    <div>
      <br />
      <p>
        Blistex Cold Sore Cream is a clinically proven treatment for the early
        stages of recurrent cold sores on the lips. it contains Docosanol which
        works differently to other treatments in the early stages of a cold
        sore.
      </p>
      <p>
        <strong>
          * Blistex Cold Sore Cream contains docosanol. always read the label.
        </strong>
      </p>
    </div>
  ),
  listID: "98baa49924",
  stockists: [
    {
      stockistName: "Boots",
      stockistLink: "https://www.boots.com/blistex-cold-sore-cream-2g-10243453",
      stockistImage: bootsLogo,
      showLink: "true",
    },
    {
      stockistName: "Tesco",
      stockistLink: "https://www.tesco.com/groceries/en-GB/products/299055788",
      stockistImage: tescoLogo,
      showLink: "true",
    },
    {
      stockistName: "Superdrug",
      stockistLink:
        "https://www.superdrug.com/Hair-Brushes-%26-Combs/Detangling-Hair-Brushes/Blistex-Coldsore-Cream-24g/p/757150",
      stockistImage: superdrugLogo,
      showLink: "true",
    },
    {
      stockistName: "Amazon",
      stockistLink: "",
      stockistImage: amazonLogo,
    },
  ],
  whenToUseCopy: (
    <div>
      <p>adults and adolescents aged 12 years and over: </p>
      <p>
        at the first sign of a recurrent cold sore, apply to the affected area 5
        times a day, at approximately 3 hourly intervals until healed, for a
        maximum of 10 days. do not start treatment with cream at the stage of an
        already developed blister or ulcer.
      </p>
      <p>
        for full details read before use and retain the enclosed patient
        information leaflet. warnings and precautions:
      </p>
      <ul>
        <li>do not use if sensitive to Docosanol or any other ingredients.</li>
        <li>children under 12 should not use Docosonal 10 % cream.</li>
        <li>
          do not use and consult your doctor if you have a weakened immune
          system.
        </li>
        <li>should only be used for cold sores on the mouth and face.</li>
        <li>avoid getting the cream into the eyes.</li>
      </ul>
      <p>
        if symptoms do not improve after 10 days, or if they worsen, consult
        your doctor.
      </p>

      <h2>what is a cold sore?</h2>
      <p>
        a cold sore starts with a tingling, burning sensation to the lip. a
        blister then appears within 48 hours and can take up to ten days to
        fully heal. flu, fatigue, stress and extreme weather conditions are just
        a few of the common triggers for cold sores, meaning that many people
        tend to suffer more cold sores during the colder months of the year.
      </p>
      <p>
        the blister may spread and be irritating or painful whilst it heals, so
        it’s advised to use a treatment such as Blistex cold sore cream to help
        aid the healing process.
      </p>
    </div>
  ),
  ingredients: (
    <p>
      1 g contains 100 mg (10 %) docosanol. also contains sucrose stearates,
      light mineral oil, propylene glycol (e1520), benzyl alcohol (e1519),
      purified water.
    </p>
  ),
  relatedProducts: [
    {
      productName: "",
      productLink: "",
      productImage: "",
    },
    {
      productName: "",
      productLink: "",
      productImage: "",
    },
  ],
}

const ColdSoreCream = () => (
  <Layout noHeaderInLayout={false} makeFooterSticky={false}>
    <SEO title="Cold Sore Cream." />
    <SingleProduct data={product} inStock={true} />
  </Layout>
)

export default ColdSoreCream
